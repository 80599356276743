"use strict";

define(['R', 'jquery', 'rivets'
        // , './landing.twig'
        // , './landing.css'
    ],
    /**
     *
     * @param {Root} R
     * @param $
     *
     * @returns {mLanding}
     */
    function(R, $)
    {

        class mLanding extends R.core.Module3 {
            constructor() {
                super();

                this.tmpl = (typeof tmpl !== 'undefined') ? tmpl : '';
            }

            _init() {
                window.mLanding = this;

                R.regMod(this);

                this.$onlineAmount = $('.m-Landing-onlineAmount');

                this.isLite = this.$mod[0].hasAttribute('data-is_lite');

                this.setListeners();
            }

            setListeners() {
                window.require(['socket'], (socket)=>{
                    socket.emit('followWhoIsOnline', (data)=>{
                        this.setTotalOnline(data);
                    });
                });
                window.require(['pubsub'], (pubsub)=>{
                    pubsub.subscribe('site.whoIsOnline', (e, data)=>{
                        this.setTotalOnline(data);
                    });
                });
            }

            setTotalOnline(amount) {
                this.$onlineAmount.html(amount);
            }

            start() {
                if (!super.start()) {
                    this.$mod.removeClass('hidden');
                }
                this.play()
            }

            play() {
                super.play();
            }

            pause() {
                super.pause();
            }

            stop() {
                this.pause();
                if (!super.stop()) {
                    this.$mod.addClass('hidden');
                }
            }
        }
        R.regModCls(mLanding);

        define("m-landing", [], function() {return mLanding;});

        return mLanding;
    });